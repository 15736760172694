.content, .author {
  margin-bottom: 100px;

  a {
    color: $font-color;
  }
}

.content {
  p, li, blockquote {
    font-size: 20px;
    line-height: 1.4em;
  }

  sup {
    font-size: 14px;
    line-height: 14px;
    padding: 2px;
  }

  .footnotes {
    border-top: 2px solid;
    margin-top: 2em;
    padding: 1em;
    font-size: 14px;
    p, li {
      font-size: 14px;
    }
  }

  h2, h3, h4 {
    margin: 3em 0 1em 0;
    clear: both;
  }

  .center {
    text-align: center;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table.center {
    th, td {
      text-align: center;
    }
  }

  table.fixed-layout {
    table-layout: fixed;
  }

  th {
    background-color: $font-color-faded;
  }

  td, th {
    border: 1px solid $font-color-faded;
    padding: 10px;
  }

  iframe {
    margin: 2em auto;
    display: block;
  }

  blockquote {
    border-left: 4px solid $font-color-faded;
    margin-left: 0;
    padding-left: 40px;
  }

  img.inline {
    max-height: 24px;
    vertical-align: middle;
  }

  img.left {
    float: left;
    max-width: 400px;
    max-height: 600px;
    margin: 1em 1em 1em -200px;
    clear: both;
  }

  img.right {
    float: right;
    max-width: 400px;
    max-height: 600px;
    margin: 1em -200px 1em 1em;
    clear: both;
  }

  img.full-width {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
    max-height: 900px;
    object-fit: cover;
  }

  .canvas-container {
    margin-left: -100px;
    margin-right: -100px;
    position: relative;
  }

  img.grimoire {
    filter: drop-shadow(1px 1px 5px rgba(0,0,0,0.33));
    max-width: 250px;
    padding: 1em;
  }

  @media (max-width: $fixed_width) {
    img.left {
      max-width: 350px;
      max-height: 500px;
      margin: 1em 1em 1em -100px;
    }

    img.right {
      max-width: 350px;
      max-height: 500px;
      margin: 1em -100px 1em 1em;
    }
  }

  @media (max-width: $medium_width) {
    img.left {
      max-width: 300px;
      max-height: 400px;
      margin: 1em 1em 1em 0;
    }

    img.right {
      max-width: 300px;
      max-height: 400px;
      margin: 1em 0 1em 1em;
    }

    .canvas-container {
      margin-left: 0;
      margin-right: 0;
      position: relative;
    }
  }

  @media (max-width: $narrow_width) {
    img.left, img.right {
      max-width: 100%;
      max-height: 300px;
      margin: 1em auto;
      float: none;
      display: block;
    }
  }
}

.author {
  position: relative;

  img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    border: 10px solid $bg-color;
    position: absolute;
    top: -60px;
    left: calc(50% - 42px);
    object-fit: cover;
    animation: spin 60s linear infinite;
  }

  hr {
    height: 2px;
    width: 100%;
    border: none;
    background: $font-color;
    margin: 50px 0;
  }

  a.twitter {
    padding-left: 28px;
    background: url('/assets/ishtar-theme/twitter.png') left 50% no-repeat;
    background-size: 20px;
  }
}

.related-posts {
  margin-bottom: $gap;
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}
