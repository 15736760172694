$body-font: 'Neue Haas Grotesk W01 Disp', 'Neue Haas Grotesk', 'Helvetica Neue', 'Arial', sans-serif;

$april-fools-body-font: 'Comic Sans', 'Comic Sans MS', Chalkboard, 'Chalkboard SE', ChalkboardSE, sans-serif;

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
