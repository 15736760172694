$archive: #4488c4;
$errata: #93cd9f;
$commons: #ee503e;

$bg-color: #F6F6F3;

$font-color: #363D3D;
$font-color-faded: #A8ACAB;
$link-color-footer: #F6F6F6;

$carousel-bg-color: lighten($font-color, 25%);
$carousel-font-color: $bg-color;

$admin-color: #9b1a97;

$card-header-bg-color: #E2E4DD;

$footer-bg-color: #363D3D;
$footer-font-color: #7E8383;
$footer-link-color: $bg-color;

$confidence-level-1-color: #6D97B6;
$confidence-level-2-color: #78D4D2;
$confidence-level-3-color: #8DEF9A;
$confidence-level-0-color: $bg-color;
$recent-item-color: #EE503E;

$new-color: #EE503E;

$diff-unchanged-bg-color:    #e7e9e3;
$diff-del-bg-color:          #fed9ca;
$diff-del-selected-bg-color: #fbbfa9;
$diff-del-font-color:        #eb372a;
$diff-ins-bg-color:          #d0fad6;
$diff-ins-selected-bg-color: #adf5b6;
$diff-ins-font-color:        #3cb72d;

$footnote-color: #EE503E;

$highlight-color: #ff7d00;

//release colors
$release-color-dark-below: #7DABCF;
$release-color-house-of-wolves: #78D4D2;
$release-color-taken-king: #7D465F;
$release-color-rise-of-iron: #D4BE29;
$release-age-of-triumph: #2b3269;
$release-destiny2: $font-color;
$release-color-destiny: $font-color;
$release-color-default: $font-color-faded;

$table-bg-color: $card-header-bg-color;
$table-border-color: #c7c9c1;
$input-bg-color: $card-header-bg-color;

$divider-color: #DDDFD7;

$supporter_level_6: #9642c5;
$supporter_level_5: #f4133d;
$supporter_level_4: #f48413;
$supporter_level_3: #57c542;
$supporter_level_2: #42c59d;
$supporter_level_1: #4286c5;
$supporter_level_0: $bg-color;
