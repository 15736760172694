$post_box_height: 300px;
$post_box_text_padding: 30px;
$post_box_text_height: $post_box_height - ($post_box_text_padding * 2);

.home.banner {
  h2 {
    padding-top: 85px;
    background: url('/assets/ishtar-theme/errata.png') top left no-repeat;
    background-size: 85px 85px;
  }
}

.post-box {
  height: $post_box_height;
  position: relative;
  border: 1px solid $font-color-faded;
  border-radius: 4px;
  .post-image {
    height: $post_box_height;
    background-size: cover;
    background-position: 50% 50%;
    // filter: brightness(90%);
    border-radius: 4px;
  }
  .post-text {
    height: $post_box_text_height;
    position: absolute;
    top: 0;
    padding: $post_box_text_padding ($post_box_text_padding * 2) $post_box_text_padding $post_box_text_padding;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color: $font-color;
    h3 {
      font-size: 32px;
    }
    h3, .description, .details {
      margin: 5px 0;
    }
  }
  &.has-image {
    border: none;
    .post-text {
      // text-shadow: 0 0 5px rgba(0,0,0,0.33);
      color: $bg-color;
    }
  }
}
