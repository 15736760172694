body, * {
  font-family: $body-font;
}

body {
  background-color: $bg-color;
  color: $font-color;
  margin: 0;
  overflow-x: hidden;
}

.full-width {}

.fixed-width {
  margin: 0 auto;
  width: $fixed_width;
}

.narrow-width {
  margin: 0 auto;
  width: $narrow_width;

  img {
    max-width: 100%;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  column-gap: $gap;
  row-gap: $gap;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: $gap;
  row-gap: $gap;
}

@for $i from 1 through 11 {
  .col-#{$i} {
    grid-column-end: span $i;
  }
}

@media (max-width: $fixed_width + 70px) {
  .fixed-width {
    margin: 0 $gap;
    width: calc(100% - 50px);
  }
}

@media (max-width: $narrow_width + 70px) {
  .fixed-width, .narrow-width {
    margin: 0 $gap;
    width: calc(100% - 50px);
  }
}

@media (max-width: 730px) {
  @for $i from 1 through 11 {
    .col-#{$i} {
      grid-column-end: span 11;
    }
  }
}

.header {
  height: 60px;
  h1 {
    margin: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    a {
      min-height: 48px;
      background-image: url('/assets/ishtar-theme/errata.png');
      background-size: 48px;
      background-position: left 50%;
      background-repeat: no-repeat;
      padding: 15px 15px 10px 60px;
      color: $font-color;
      text-decoration: none;
    }
  }
}

.banner {
  height: 600px;
  color: $bg-color;
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 50px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  h2, h3 {
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.75));
  }

  h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 48px;
  }

  h3 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: normal;
  }
}
